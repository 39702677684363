import { IProductEntity } from 'src/_shared/entities'

export const isEmail = (value: string) => {
	const result = value
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		)
	return !!result
}

/**
 * Делает первую букву заглавной
 * @param value
 * @returns {string}
 */
export const capitalize = (value: string): string => {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
}

/**
 * Возвращает отформатированную сумму с валютой, например "1000" => "1 000 Р"
 * @param value
 * @returns {string}
 */
export function rub(value: number): string {
	if (!value) return ''
	return new Intl.NumberFormat('ru-RU', {
		style: 'currency',
		useGrouping: true,
		currency: 'RUB',
	})
		.format(value)
		.replace(/,00/g, '')
}

/**
 * Возвращает склонённый суфикс к данному числу
 * @param num
 * @param words125
 * @returns string
 */
export function plural(num: number, words125: string[]): string {
	// words = ['заявка', 'заявки', 'заявок']
	// words для [1,2,5];
	const cases = [2, 0, 1, 1, 1, 2]
	return words125[
		num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
	]
}

/**
 * Возвращает сгрупированный по имени товара список товаров
 * @param Array<IProductEntity>
 * @returns Array<string, IProductEntity[]>
 */
export const groupByName = (
	products: IProductEntity[],
): Record<string, IProductEntity[]> => {
	return products.reduce(
		(items, item) => {
			if (!items[item.name]) {
				items[item.name] = []
			}
			items[item.name].push(item)
			return items
		},
		{} as Record<string, IProductEntity[]>,
	)
}
/**
 * Возвращает расстояние между двумя координатами с учётом кривизны нашей планеты )
 * @returns {number} в метрах
 * @param point1
 * @param point2
 */
export function getDistance(
	point1: [number, number],
	point2: [number, number],
) {
	const [lat1, lon1] = point1
	const [lat2, lon2] = point2
	function toRad(value: number) {
		return (value * Math.PI) / 180
	}
	const a = 6378137
	const b = 6356752.314245
	const f = 1 / 298.257223563
	const L = toRad(lon2 - lon1)
	const U1 = Math.atan((1 - f) * Math.tan(toRad(lat1)))
	const U2 = Math.atan((1 - f) * Math.tan(toRad(lat2)))
	const sinU1 = Math.sin(U1)
	const cosU1 = Math.cos(U1)
	const sinU2 = Math.sin(U2)
	const cosU2 = Math.cos(U2)

	let lambda = L
	let lambdaP
	let iterLimit = 100
	let cosSigma
	let cosLambda
	let sigma
	let sinAlpha
	let cosSqAlpha
	let cos2SigmaM
	let sinSigma
	let sinLambda
	do {
		sinLambda = Math.sin(lambda)
		cosLambda = Math.cos(lambda)
		sinSigma = Math.sqrt(
			cosU2 * sinLambda * (cosU2 * sinLambda) +
				(cosU1 * sinU2 - sinU1 * cosU2 * cosLambda) *
					(cosU1 * sinU2 - sinU1 * cosU2 * cosLambda),
		)
		if (sinSigma === 0) return 0

		cosSigma = sinU1 * sinU2 + cosU1 * cosU2 * cosLambda
		sigma = Math.atan2(sinSigma, cosSigma)
		sinAlpha = (cosU1 * cosU2 * sinLambda) / sinSigma
		cosSqAlpha = 1 - sinAlpha * sinAlpha
		cos2SigmaM = cosSigma - (2 * sinU1 * sinU2) / cosSqAlpha
		if (isNaN(cos2SigmaM)) cos2SigmaM = 0
		const C = (f / 16) * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha))
		lambdaP = lambda
		lambda =
			L +
			(1 - C) *
				f *
				sinAlpha *
				(sigma +
					C *
						sinSigma *
						(cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)))
	} while (Math.abs(lambda - lambdaP) > 1e-12 && --iterLimit > 0)

	if (iterLimit === 0) return NaN

	const uSq = (cosSqAlpha * (a * a - b * b)) / (b * b)
	const A = 1 + (uSq / 16384) * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq)))
	const B = (uSq / 1024) * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)))
	const deltaSigma =
		B *
		sinSigma *
		(cos2SigmaM +
			(B / 4) *
				(cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) -
					(B / 6) *
						cos2SigmaM *
						(-3 + 4 * sinSigma * sinSigma) *
						(-3 + 4 * cos2SigmaM * cos2SigmaM)))
	return b * A * (sigma - deltaSigma)
}

export const formatPhone = (phone: string): string | null => {
	const parts = phone.match(/^(\+[7]|)?(\d{3})(\d{3})(\d{4})$/) as string[]
	if (parts) {
		const code = parts[1] ?? ''
		return `${code} (${parts[2]}) ${parts[3]} ${parts[4]}`
	}
	return null
}

export const formatNumbers = (number: number) =>
	number.toString().replace(/\d+?(?=(?:\d{3})+$)/gim, '$& ')

export const formatDate = (timeStamp: Date) => {
	if (!timeStamp) return ''
	const d = new Date(timeStamp).getTime()
	return new Date(d - new Date().getTimezoneOffset() * 60 * 1000)
		.toISOString()
		.slice(0, -5)
		.split('T')
		.join(' ')
		.slice(0, -3)
}

export const formatTimeAgo = (
	dateString: Date | string | undefined,
): string => {
	if (!dateString) return ''

	const date = new Date(dateString)
	const now = new Date()
	const elapsedMilliseconds = now.getTime() - date.getTime()
	const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000)
	const elapsedMinutes = Math.floor(elapsedSeconds / 60)
	const elapsedHours = Math.floor(elapsedMinutes / 60)
	const elapsedDays = Math.floor(elapsedHours / 24)
	const elapsedWeeks = Math.floor(elapsedDays / 7)

	if (elapsedMilliseconds < 1000) {
		return 'Just now'
	}
	if (elapsedSeconds < 60) {
		return `${elapsedSeconds} seconds ago`
	}
	if (elapsedMinutes < 60) {
		return `${elapsedMinutes} minutes ago`
	}
	if (elapsedHours < 24) {
		return `${elapsedHours} hours ago`
	}
	if (elapsedDays < 7) {
		return `${elapsedDays} days ago`
	}
	if (elapsedWeeks < 4) {
		return `${elapsedWeeks} weeks ago`
	}
	const monthsAgo = Math.floor(elapsedDays / 30)
	if (monthsAgo < 12) {
		return `${monthsAgo} months ago`
	}
	const yearsAgo = Math.floor(monthsAgo / 12)
	return `${yearsAgo} years ago`
}

export const exportToCsv = (arrData: Array<any>) => {
	let csvContent = 'data:text/csv;charset=utf-8,'
	csvContent += [
		Object.keys(arrData[0]).join(';'),
		...arrData.map((item) => Object.values(item).join(';')),
	]
		.join('\n')
		.replace(/(^\[)|(\]$)/gm, '')

	const data = encodeURI(csvContent)
	const link = document.createElement('a')
	link.setAttribute('href', data)
	link.setAttribute('download', 'export.csv')
	link.click()
}
