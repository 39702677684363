import { defineStore } from 'pinia'
import { IProductEntity } from 'src/_shared/entities'

export const useProductStore = defineStore('productStore', {
	state: () => ({
		items: [
			{
				id: 1,
				name: 'Pepsi 0.33 л',
				description: 'Some description of a product to display on the page',
				cost: 400,
				image: 'test/pepsi.png',
			},
			{
				id: 2,
				name: 'Fuse Tea Лимон 500 мл',
				description: 'Some description of a product to display on the page',
				cost: 400,
				image: 'test/fuse.png',
			},
			{
				id: 3,
				name: 'Green Apple',
				description: 'Some description of a product to display on the page',
				cost: 400,
				image: 'test/fuse.png',
			},
			{
				id: 4,
				name: 'Sepsi 506 мл',
				description: 'Some description of a product to display on the page',
				cost: 400,
				image: 'test/pepsi.png',
			},
			{
				id: 5,
				name: 'Milk 500 мл',
				description: 'Some description of a product to display on the page',
				cost: 400,
				image: 'test/fuse.png',
			},
			{
				id: 11,
				name: 'Coca Cola 0.33 л',
				description: 'Some description of a product to display on the page',
				cost: 400,
				image: 'test/pepsi.png',
			},
		] as IProductEntity[],
	}),
	getters: {},
	actions: {
		async empty(): Promise<void> {
			this.$reset()
		},
		async searchProduct(search: string): Promise<string> {
			return search
		},
	},

	persistedState: {
		persist: true,
	},
})
