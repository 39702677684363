import { defineStore } from 'pinia'
import { IProductEntity } from 'src/_shared/entities'

export const useTradeObjectStore = defineStore('tradeObjectStore', {
	state: () => ({
		items: [
			{
				id: 1,
				name: 'ASSA store',
				description: 'Some description of a store to display on the page',
			},
			{
				id: 2,
				name: 'Magnum',
				description: 'I hope some day they will be our client',
			},
			{
				id: 3,
				name: 'Small',
				description:
					'I hope some day they will be our client, Some description of a product to display on the page',
			},
			{
				id: 4,
				name: 'Nike store',
				description: 'Some description of a nike store to display on the page',
			},
			{
				id: 5,
				name: 'Baraholka',
				description: 'We need to think how we can take that spot',
			},
		] as IProductEntity[],
	}),
	getters: {},
	actions: {
		async empty(): Promise<void> {
			this.$reset()
		},
	},

	persistedState: {
		persist: true,
	},
})
