import script from "./product-list.vue?vue&type=script&setup=true&lang=ts"
export * from "./product-list.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../node_modules/.pnpm/@quasar+app-webpack@3.13.2_eslint@8.57.0_pinia@2.1.7_typescript@4.9.4_vue@3.4.27_typescript@4_cvl43iagsypzyn3ncb6n5zdc6y/node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QExpansionItem,QItemSection,QAvatar,QItemLabel});
