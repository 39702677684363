import { defineStore } from 'pinia'
import { IProductEntity } from 'src/_shared/entities'
import { useProductStore } from 'stores/product.store'

const productStore = useProductStore()
export const useCartStore = defineStore('cartStore', {
	state: () => ({
		items: [
			{ id: 1, name: 'Pepsi 0.33 л', cost: 400, image: 'test/pepsi.png' },
			{
				id: 2,
				name: 'Fuse Tea Лимон 500 мл',
				cost: 400,
				image: 'test/fuse.png',
			},
		] as IProductEntity[],
	}),
	getters: {},
	actions: {
		totalPrice(): number {
			return this.items.reduce((total, item) => (total += item.cost), 0)
		},
		removeItem(name: string) {
			const elemToRemove = this.items.findIndex((item) => item.name === name)
			if (elemToRemove >= 0) {
				this.items.splice(elemToRemove, 1)
			}
		},
		addItem(name: string) {
			const itemToAdd = productStore.items.find((item) => item.name === name)
			if (itemToAdd) this.items.push(itemToAdd)
		},
		async empty(): Promise<void> {
			this.$reset()
		},
	},
	persistedState: {
		persist: true,
	},
})

// export const useCartStore = defineStore('cartStore', () => {
// 	// const items = ref([] as IProductEntity[])
// 	const items = ref([
// 		{ id: 1, title: 'Pepsi 0.33 л', price: 400, img: 'test/pepsi.png' },
// 		{ id: 2, title: 'Fuse Tea Лимон 500 мл', price: 400, img: 'test/fuse.png' },
// 	])
//
// 	const empty = async (): Promise<void> => {
// 		items.value = []
// 	}
//
// 	const countTotalPrice = () => {
// 		const totalPrice = items.value.reduce(
// 			(total, item) => total + item.price,
// 			0,
// 		)
// 		return totalPrice
// 	}
// 	const addToCart = (product: (typeof items.value)[0]) => {
// 		items.value.push(product)
// 	}
//
// 	return {
// 		items,
// 		empty,
// 		countTotalPrice,
// 		addToCart,
// 	}
// })
